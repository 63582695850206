<template>
    <div class="SurplusEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            ref="editForm"
            :model="form"
            :rules="rules"
            label-width="80px"
            @submit.native.prevent
            size="small"
            :inline="true"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="补货机构">
                            <el-input v-model="form.deptName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="form.creator" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input v-model="form.createTime" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-col :span="8">
                    <el-form-item label="查询条件" prop="search">
                        <el-input placeholder="商品名称/条码/助记码/自编码" v-model="search" />
                    </el-form-item>
                </el-col>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" />
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" :disabled="disableQueryAndAddButton" @click="queryAndAdd" size="small">
                    查询并添加
                </el-button>
                <el-button
                    type="primary"
                    :disabled="disableShowPickUpGoodsDialog"
                    @click="showPickUpGoods"
                    size="small"
                >
                    选择商品基本信息
                </el-button>
                <el-button type="primary" :loading="saveLoading" @click="handleSave" size="small">保存</el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    v-loading="tableLoading"
                    :highlight-current-row="true"
                    max-height="440"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column prop="sku.name" label="商品名称" width="180" />
                    <el-table-column label="条码">
                        <template slot-scope="scope">
                            <span>{{ scope.row.sku.bars.map((d) => d).join(' , ') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品类型" width="80">
                        <template slot-scope="scope">
                            <span>{{ scope.row.sku.type | goodsType }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="助记码" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.fastBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="自编码" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.customBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.sku.specs }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="sku.category" label="类目" width="120" />
                    <el-table-column prop="sku.unit" label="单位" width="80" />
                    <el-table-column prop="sku.brand" label="品牌" width="80" />
                    <el-table-column label="调整数量" width="170">
                        <template slot-scope="scope">
                            <el-form-item>
                                <el-input-number
                                    v-model="scope.row.repayGoodsCount"
                                    size="mini"
                                    :min="0"
                                    :controls="false"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <pick-up-goods ref="pickupGoods" :selfCanClose="false" @queryGoods="setSelectedGoods" />
    </div>
</template>
<script>
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';
import EfRemark from 'components/EfRemark';
import StockBizCommon, { PropertyConverter, wholeSalePrice } from 'js/StockBizCommon';
import {
    deptCodeValidateRule,
    goodsCodeValidateRule,
    remarkNotRequiredValidateRule,
} from 'js/validate/ValidateCommonRule';
import {
    abstractCodeValidateRule,
    countGreaterThanZeroValidateRule,
    countValidateRule,
} from 'js/validate/ValidateStockBizRule';
import GoodsCommon from 'js/GoodsCommon';
import MoneyUtils from 'js/MoneyUtils';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'ReplenishmentEdit',
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    components: { PickUpGoods, EfRemark },
    data() {
        return {
            formExtend: {
                deptTypeName: '',
                createTime: '',
                creator: '',
            },
            search: '',
            form: {
                code: '',
                deptCode: '',
                deptName: '',
                remark: '',
                abstractCode: '',
                detailParams: [],
                delCodes: [],
            },

            //所选商品详情列表
            goodsDetailMap: new Map(),

            adjustAbstractType: StockBizCommon.AdjustAbstractTypeEnum.OVERFLOW,

            rules: {
                deptCode: deptCodeValidateRule(),
                abstractCode: abstractCodeValidateRule(),
                remark: remarkNotRequiredValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                counts: [
                    countValidateRule(
                        () => (this.form || {}).detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                    countGreaterThanZeroValidateRule(),
                ],
            },
            tableLoading: true,
            saveLoading: false,
            codeMap: [],
            url: {
                queryGoods: '/goods/goods/list_c',
            },
        };
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            const deptCode = this.form.deptCode;
            const search = this.search;
            return deptCode == null || deptCode === '' || search == null || search === '';
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            const deptCode = this.form.deptCode;
            return deptCode == null || deptCode === '';
        },
        //禁用/启用按钮
        disableSaveButton() {
            const deptCode = this.form.deptCode;
            const detailParams = this.form.detailParams;
            return deptCode == null || deptCode === '' || detailParams == null || detailParams.length === 0;
        },
    },
    mounted() {
        this.code = this.$router.history.current.params.code;
        const _this = this;
        let dataArr = [];
        UrlUtils.QueryRemote(this, 'repayDeptGoods/bizDetail/' + this.code, (rst) => {
            this.form.deptName = rst.deptName;
            this.form.deptCode = rst.deptCode;
            this.form.creator = rst.creator;
            this.form.createTime = rst.createTime;
            this.form.remark = rst.createTime;
            dataArr = rst.details;
            dataArr.forEach((v, i) => {
                _this.codeMap.push(v.goodsCode);
            });
        });

        setTimeout(() => {
            if (this.codeMap.length) {
                UrlUtils.QueryGoodsInfoByCodes(this, this.url.queryGoods, { codes: this.codeMap }, (data) => {
                    data.forEach((v, i) => {
                        dataArr.forEach((item, idx) => {
                            if (item.goodsCode === v.code) {
                                item.sku = v.sku;
                                item.fastBar = v.fastBar;
                                item.customBar = v.customBar;
                            }
                        });
                        this.form.detailParams = dataArr;
                        this.tableLoading = false;
                    });
                });
            }
        }, 350);

        this.tableLoading = false;
    },
    methods: {
        detailParamPropertyArrForShow() {
            return [
                'codes',
                'goodsCode',
                'counts',
                new PropertyConverter('wholeSalePrices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
            ];
        },
        detailParamPropertyArrForAdd() {
            return [
                'goodsCode',
                new PropertyConverter(null, 'counts'),
                new PropertyConverter(wholeSalePrice, 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
            ];
        },
        detailParamPropertyArrForSubmit() {
            return [
                'codes',
                'goodsCode',
                'counts',
                new PropertyConverter('showPrices', 'wholeSalePrices', (price) => {
                    return MoneyUtils.moneyToDb(price);
                }),
            ];
        },
        setSelectedGoods(selectedGoods) {
            const oldDetails = Object.assign([], this.form.detailParams || []);
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArrForAdd()
            );
            if (oldDetails.length <= 150) {
                this.form.detailParams = oldDetails;
                this.$refs.pickupGoods.dialogFormVisible = false;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
        showPickUpGoods() {
            this.$refs.pickupGoods.show({ deptCode: this.form.deptCode }, this.form.deptName);
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ deptCode: this.form.deptCode, search: this.search });
            this.setSelectedGoods(rst.data);
        },
        handleSave() {
            this.$refs.editForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                if (this.form.detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }
                this.saveLoading = true;
                //将商品详情列表转换成多字段数组形式
                const updateParam = {
                    ...this.form,
                    ...StockBizCommon.convertStockBizDetailParamsToParamArrObj(
                        this.form.detailParams,
                        this.detailParamPropertyArrForSubmit()
                    ),
                };
                //删除非提交必要字段数据
                delete updateParam.detailParams;
                const _params = [];
                this.form.detailParams.forEach((item) => {
                    _params.push({
                        goodsCode: item.goodsCode,
                        repayGoodsCount: item.repayGoodsCount,
                    });
                });
                this.$http
                    .post(
                        `/repayDeptGoods/editExtend?deptCode=${this.form.deptCode}&remark=${this.form.remark || ''}`,
                        _params
                    )
                    .then((rst) => {
                        this.goBackAndReload();
                        this.saveLoading = false;
                    });
                this.saveLoading = false;
                // await this.$efApi.stockSurplusApi.updateByFormData(this.form.code, updateParam).finally(() => {
                //     this.saveLoading = false;
                // });
                // this.goBackAndReload();
            });
        },
        handleDelete(index) {
            const detailCode = this.form.detailParams.splice(index, 1)[0].codes;
            if (Util.isEmpty(detailCode)) {
                //不存在，说明是单据详情中新增的商品被删除，则不需要处理
                return;
            }
            this.form.delCodes.push(detailCode);
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
    },
};
</script>
